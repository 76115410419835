// dependencies.
import React from 'react'
import Img from 'gatsby-image'
// components.
import Button from 'src/components/button'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'

// styles & images.
import 'src/components/pages/waas/scss/HeaderSection.scss'

const background = {
  aspectRatio: 1.677,
  path: '/waas/img/',
  fileType: 'png',
  webp: false,
  files: [{ fileName: 'header-bg-lsize', width: 3200 }],
}

const devices = {
  aspectRatio: 0.867,
  path: '/waas/img/',
  fileType: 'png',
  webp: false,
  files: [{ fileName: 'hero-devices-lsize', width: 1360 }],
}

// main component.
const HeaderSection = ({ page, data, enableSubscribe, onButtonClick }) => {
  return (
    <header className="x__waas-header">
      {background && (
        <div
          className={`x__waas-header__background ${page}-rellax`}
          data-rellax-speed="-4"
          role="presentation"
        >
          <Img
            fluid={buildImageObject(background)}
            durationFadeIn={2000}
            className="x__waas-header__background__image"
            alt={data.alt}
          />
        </div>
      )}

      <div className="x__waas-header__content">
        <div className="x__waas-header__content--left">
          <h1
            className="x__waas-header__content__heading"
            dangerouslySetInnerHTML={{ __html: data.h1 }}
          />
          <h2
            className="x__waas-header__content__copy"
            dangerouslySetInnerHTML={{ __html: data.copy }}
          />
          {enableSubscribe && (
            <Button copy={data.cta.copy} onClick={onButtonClick} size="largest" />
          )}
        </div>

        <div className="x__waas-header__content--right">
          {devices && (
            <div className="x__waas-header__content__devices">
              <Img fluid={buildImageObject(devices)} durationFadeIn={1000} alt={data.alt} />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
