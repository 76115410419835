// dependencies.
import React from 'react'
import classNames from 'classnames'

// styles & images.
import 'src/components/pages/waas/scss/Button.scss'

// main component.
const Button = ({ copy, disabled, state = 'initial', onClick }) => {
  return (
    <button
      className={classNames(`x__waas-button x__waas-button--primary`, {
        'x__waas-button--disabled': disabled,
        'x__waas-button--loading': state === 'loading',
        'x__waas-button--success': state === 'success',
      })}
      disabled={disabled}
      onClick={onClick}
      tabIndex={0}
    >
      <span>
        {state === 'initial' && <span className="x__waas-button__text">{copy}</span>}
        {state === 'loading' && <span className="x__waas-button__text">loading...</span>}
        {state === 'success' && <span className="x__waas-button__success">⎷</span>}
      </span>
    </button>
  )
}

export default Button
