// dependencies.
import React from 'react'
import classNames from 'classnames'

// styles & images.
import 'src/components/pages/waas/scss/AboutSection.scss'

// partials.
const ChromeImage = ({ page, index, containerClassName, speed }) => (
  <span
    className={classNames(`${containerClassName}__chrome ${containerClassName}__chrome--${index}`, {
      [`${page}-rellax`]: speed,
    })}
    data-rellax-speed={speed}
    role="presentation"
  />
)

// main component.
const AboutSection = ({ page, data, style = 1 }) => {
  const chromeProps = { page, containerClassName: 'x-about' }

  return (
    <section className="x-about">
      <div className={`x-about__content x-about__content--${style}`}>
        <div className="x-about__content__headings">
          <h2 className="x-about__content__heading" dangerouslySetInnerHTML={{ __html: data.h2 }} />
          {data.copy && (
            <p
              className="x-about__content__description"
              dangerouslySetInnerHTML={{ __html: data.copy }}
            />
          )}
          {data.h3 && (
            <h3
              className="x-about__content__subheading"
              dangerouslySetInnerHTML={{ __html: data.h3 }}
            />
          )}
        </div>

        {data.items && data.items.length > 0 && (
          <ul className="x-about__content__items">
            {data.items.map((item, i) => (
              <li key={i}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        )}

        {style === 1 && (
          <>
            <ChromeImage {...chromeProps} index={1} speed={1} />
            <ChromeImage {...chromeProps} index={2} speed={2} />
          </>
        )}
      </div>
    </section>
  )
}

export default AboutSection
