// dependencies.
import React from 'react'
import Img from 'gatsby-image'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'

// styles & images.
import 'src/components/pages/waas/scss/InfoSection.scss'

// main component.
const InfoSection = ({ data, style = 1 }) => {
  const devices = [
    {
      aspectRatio: 0.841,
      path: '/waas/img/',
      fileType: 'png',
      webp: false,
      files: [{ fileName: `${data.imgName}-lsize`, width: 1036 }],
    },
  ]

  return (
    <section className="x-info">
      <div className="x-info__content">
        <div className={`x-info__content--left x-info__content--left--${style}`}>
          <h2 className="x-info__content__heading" dangerouslySetInnerHTML={{ __html: data.h2 }} />
          {data.copy && (
            <p
              className="x-info__content__description"
              dangerouslySetInnerHTML={{ __html: data.copy }}
            />
          )}
          {data.items.length > 0 && (
            <ul className="x-info__content__items">
              {data.items.map((item, i) => (
                <li key={i} className="x-info__content__items__item">
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={`x-info__content--right x-info__content--right--${style}`}>
          {devices &&
            devices.length > 0 &&
            devices.map((device, i) => (
              <div className={`x-info__content__device x-info__content__device--${i + 1}`} key={i}>
                <Img fluid={buildImageObject(device)} durationFadeIn={1000} alt={data.h2} />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default InfoSection
