// dependencies.
import React, { useEffect, useState } from 'react'
import EmailValidator from 'email-validator'
// components.
import PageHead from 'src/components/head/PageHead'
import MagicEdenBanner from 'src/components/banner/MagicEdenBanner'
import {
  AboutSection,
  FooterSection,
  FormModal,
  FeaturesSection,
  HeaderSection,
  InfoSection,
} from 'src/components/pages/waas'
import { Header, Footer } from 'src/components/layout'
// utils.
import { getCookie, setCookie } from 'src/js/utils/cookies'
import { useRellax } from 'src/js/utils/hooks'
// data.
import WaasData from 'src/data/pages/Contents/wallet-as-a-service.json'
// constants.
import { HELPERS_URL, WAAS_LEAD_SUBSCRIBE_ENABLED } from 'src/constants'

// Styles & Images
import 'static/waas/scss/styles.scss'

// Helpers:
/*
 * Related cookies
 * ex_waas_lead_subscribe
 *  - set to "1" for 30 days if the user subscribes
 */
const leadCookie = 'ex_waas_lead_subscribe'
const isCookieSet = () => getCookie(leadCookie) === '1'

// Main component:
const WaasPage = ({ pageName = 'wallet-as-a-service', pageData = WaasData }) => {
  // get page data.
  const page = pageName
  const { header, footer, about, banner, info, features, product, partnership, form } = pageData

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [formState, setFormState] = useState('initial')

  const handleModalOpen = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const handleFormSubmit = (values) => {
    if (!values) return null

    if (!values.email || !EmailValidator.validate(values.email)) {
      setFormState('error')
      return
    }

    setFormState('loading')

    fetch(`${HELPERS_URL}/waas/add-lead`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const success = response.status === 200
        const invalid = response.status === 400

        if (success) {
          setFormState('success')
          if (!isCookieSet()) setCookie({ name: leadCookie, value: '1', daysToPersist: 30 })
        } else {
          setFormState(invalid ? 'invalid' : 'error')
        }
      })
      .catch((err) => {
        console.log(err)
        setFormState('error')
      })
  }

  useEffect(() => {
    if (typeof document !== 'undefined' && isCookieSet()) setFormState('success')
    else setFormState('initial')
  }, [])

  useRellax({ className: `${page}-rellax` })

  return (
    <div className="x-page-waas">
      <main className="x">
        <Header />

        {header && (
          <HeaderSection
            page={page}
            data={header}
            enableSubscribe={WAAS_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
        {about && <AboutSection page={page} data={about} />}
        {banner && <MagicEdenBanner page={page} data={banner} />}
        {info && <InfoSection data={info[0]} />}
        {features && <FeaturesSection data={features[0]} />}
        {product && <InfoSection data={product} style={2} />}
        {partnership && <AboutSection page={page} data={partnership} style={2} />}

        {footer && (
          <FooterSection
            data={footer}
            enableSubscribe={WAAS_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
      </main>

      <FormModal
        data={form}
        modalOpen={modalIsOpen}
        state={formState}
        onClose={handleModalOpen}
        onSubmit={(values) => handleFormSubmit(values)}
      />

      <Footer />
    </div>
  )
}

export default WaasPage

// <head> component:
export function Head() {
  return <PageHead page="wallet-as-a-service" />
}
