// dependencies.
import React from 'react'

// styles & images.
import 'src/components/pages/waas/scss/FeaturesSection.scss'

import FeatureIconPlatforms from 'static/waas/img/platforms.svg'
import FeatureIconNetworks from 'static/waas/img/networks.svg'
import FeatureIconWallets from 'static/waas/img/wallets.svg'
import FeatureIconChains from 'static/waas/img/chains.svg'
import FeatureIconNotifications from 'static/waas/img/notifications.svg'
import FeatureIconRamps from 'static/waas/img/ramps.svg'

const featureIcon = {
  platforms: FeatureIconPlatforms,
  networks: FeatureIconNetworks,
  wallets: FeatureIconWallets,
  chains: FeatureIconChains,
  notifications: FeatureIconNotifications,
  ramps: FeatureIconRamps,
}

// main component.
const FeaturesSection = ({ data }) => {
  return (
    <section className="x-features">
      <div className="x-features__content">
        <h2
          className="x-features__content__heading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />

        {data.items && data.items.length > 0 && (
          <ul className="x-features__content__items">
            {data.items.map(({ key, h4, copy, items }) => (
              <li key={key}>
                <img src={featureIcon[key]} alt={key} />

                <h4 dangerouslySetInnerHTML={{ __html: h4 }} />

                {copy && <p>{copy}</p>}

                {items && items.length > 0 && (
                  <ul>
                    {items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default FeaturesSection
