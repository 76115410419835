// dependencies.
import React, { useEffect, useRef, useState } from 'react'
import EmailValidator from 'email-validator'
import classNames from 'classnames'
// components.
import Button from 'src/components/pages/waas/Button'
// utils.
import { useClickOutside, useFocus } from 'src/js/utils/hooks'

// styles & images.
import 'src/components/pages/waas/scss/FormModal.scss'

// partials.
const CloseButton = ({ onClick }) => {
  const handleOnClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <button className="x__form-modal__close" onClick={(e) => handleOnClick(e)}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.18192 0.474877C0.98666 0.279615 0.670078 0.279615 0.474816 0.474877C0.279554 0.670139 0.279554 0.986722 0.474816 1.18198L6.1317 6.83887L0.474875 12.4957C0.279613 12.691 0.279612 13.0075 0.474874 13.2028C0.670137 13.3981 0.986719 13.3981 1.18198 13.2028L6.83881 7.54597L12.4956 13.2028C12.6909 13.3981 13.0075 13.3981 13.2027 13.2028C13.398 13.0075 13.398 12.691 13.2027 12.4957L7.54591 6.83887L13.2028 1.18198C13.3981 0.986722 13.3981 0.670139 13.2028 0.474877C13.0075 0.279615 12.691 0.279615 12.4957 0.474877L6.83881 6.13176L1.18192 0.474877Z" />
      </svg>
    </button>
  )
}

const TextInput = ({ type, name, placeholder, onChange }) => {
  const [value, setValue] = useState('')
  const [hasError, setHasError] = useState(false)
  const textInputRef = useRef(null)
  const { focused } = useFocus(textInputRef)

  const handleInputChange = () => {
    const currentValue = textInputRef.current.value

    if (type === 'email') {
      setHasError(!EmailValidator.validate(currentValue))
    }

    setValue(currentValue)
  }

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <div
      className={classNames('x__form-modal__input-wrapper', {
        'x__form-modal__input-wrapper--focus': focused,
        'x__form-modal__input-wrapper--error': hasError,
      })}
    >
      <input
        ref={textInputRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value || ''}
        onKeyUp={() => handleInputChange()}
        onChange={() => handleInputChange()}
        required
      />
    </div>
  )
}

// main component.
const FormModal = ({ data, modalOpen, state, onSubmit, onClose }) => {
  const dialogRef = useRef(null)
  const [isOpen, setIsOpen] = useState(modalOpen)
  const [values, setValues] = useState({})
  const [valuesCheck, setValuesCheck] = useState(false)

  const isSubmitted = state === 'success'

  const checkValuesArePresent = (arr, obj) => {
    if (!arr || arr.length === 0) return false

    const allKeysHaveValue = arr.every((item) => {
      const key = item.name
      return key in obj && obj[key] !== '' && obj[key] !== null && obj[key] !== undefined
    })

    if (allKeysHaveValue) setValuesCheck(true)
    else setValuesCheck(false)
  }

  const handleButtonClick = () => {
    if (isSubmitted) handleCloseClick()
    else valuesCheck && onSubmit(values)
  }

  const handleCloseClick = () => {
    isOpen && onClose()
  }

  const handleInputChange = (key, value) => {
    setValues({ ...values, [key]: value })
  }

  useEffect(() => {
    checkValuesArePresent(data.default.items, values)
  }, [data.default.items, values])

  useEffect(() => {
    // prevent build from failing.
    if (typeof document === 'undefined' || typeof window === 'undefined') return

    setIsOpen(modalOpen)

    // lock document scroll.
    const body = document.body
    const bodyStyle = body.style

    if (modalOpen) {
      const scrollY = window.scrollY || window.pageYOffset
      body.dataset.scrollY = scrollY
      bodyStyle.position = 'fixed'
      bodyStyle.top = `-${scrollY}px`
      bodyStyle.left = 0
      bodyStyle.width = '100%'
    } else {
      const scrollY = parseInt(body.dataset.scrollY || '0')
      bodyStyle.position = ''
      bodyStyle.top = ''
      bodyStyle.left = ''
      bodyStyle.width = ''
      window.scrollTo(0, scrollY)
    }
  }, [modalOpen])

  const isOutside = useClickOutside(dialogRef)

  useEffect(() => {
    if (isOpen && isOutside) handleCloseClick()
  }, [isOpen, isOutside])

  return (
    <section className={classNames('x__form-modal', { 'x__form-modal--open': isOpen })}>
      <div ref={dialogRef} className="x__form-modal__content">
        <CloseButton onClick={() => handleCloseClick()} />

        {isSubmitted ? (
          <div className="x__form-modal__content--submitted">
            <div className="x__form-modal__content-wrapper">
              <h3
                className="x__form-modal__content__heading"
                dangerouslySetInnerHTML={{ __html: data.submitted.h3 }}
              />

              <p className="x__form-modal__content__description">{data.message.success}</p>
            </div>

            <Button copy={data.submitted.cta} onClick={() => handleButtonClick()} />
          </div>
        ) : (
          <>
            <h3
              className="x__form-modal__content__heading"
              dangerouslySetInnerHTML={{ __html: data.default.h3 }}
            />

            <div>
              {data.default.items &&
                data.default.items.length > 0 &&
                data.default.items.map((field, f) => (
                  <TextInput
                    key={f}
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(value) => handleInputChange(field.name, value)}
                  />
                ))}

              <p className="x__form-modal__content__message">
                {state === 'error' || state === 'invalid' ? data.message[state] : ''}
              </p>

              <Button
                copy={data.default.cta}
                disabled={!valuesCheck}
                state={state}
                onClick={() => handleButtonClick()}
              />
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default FormModal
