// dependencies.
import React from 'react'
import Img from 'gatsby-image'
import classNames from 'classnames'
// components.
import Button from 'src/components/button'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'

// Styles & Images:
import meExodusLogos from 'static/waas/img/me-exodus-logos.svg'
import 'src/components/banner/scss/MagicEdenBanner.scss'

const background = {
  aspectRatio: 4.055,
  path: '/waas/img/',
  fileType: 'jpeg',
  webp: false,
  files: [
    { fileName: 'me-banner-bg--msize', width: 998 },
    { fileName: 'me-banner-bg--lsize', width: 2344 },
  ],
}

// partials.
const ChromeImage = ({ page, index, containerClassName, speed }) => (
  <span
    className={classNames(`${containerClassName}__chrome ${containerClassName}__chrome--${index}`, {
      [`${page}-rellax`]: speed,
    })}
    data-rellax-speed={speed}
    role="presentation"
  />
)

// main component.
const MagicEdenBanner = ({ page, data }) => {
  const chromeProps = { page, containerClassName: 'x__magic-eden-banner' }

  return (
    <div className="x__magic-eden-banner__wrapper">
      <article className="x__magic-eden-banner">
        {background && (
          <div className="x__magic-eden-banner__background" role="presentation">
            <Img
              fluid={buildImageObject(background)}
              durationFadeIn={2000}
              className="x__magic-eden-banner__background__image"
              alt={data.h2}
            />
          </div>
        )}

        <div className="x__magic-eden-banner__content">
          <img className="x__magic-eden-banner__logos" src={meExodusLogos} />
          <h2 className="x__magic-eden-banner__heading">{data.h2}</h2>
          <p className="x__magic-eden-banner__description">{data.copy}</p>
        </div>

        <Button copy={data.cta.copy} to={data.cta.path} target="_blank" size="largest" />
      </article>

      <ChromeImage {...chromeProps} index={3} speed={3} />
    </div>
  )
}

export default MagicEdenBanner
